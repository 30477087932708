import React, { useState } from 'react'
import { HamburgerElastic } from 'react-animated-burgers'
import { colors } from '../theme'

const Hamburger = props => {
  const [isActive, setActive] = useState(false)

  const toggleButton = () => {
    setActive(!isActive)
  }

  return (
    <HamburgerElastic
      {...props}
      isActive={isActive}
      toggleButton={toggleButton}
      barColor={`${colors.white}`}
      buttonStyle={{ outline: 0 }}
      buttonWidth={30}
    />
  )
}

export default Hamburger
