import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { Flex } from 'rebass'

import { above } from '../theme'
import ContactLink from '../elements/ContactLink'

const NavItem = styled.li`
  font-family: ${props => props.theme.fonts.sans};
  font-size: 1.25em;
  font-weight: 600;
  display: block;
  list-style: none;
  padding: 0.25em 0.5em;
  a {
    color: ${props => props.theme.colors.white};
    padding: 0;
    position: relative;
    text-decoration: none;
  }
  a::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${props => props.theme.colors.white};
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.5s ease;
  }

  a:hover::after {
    transform-origin: bottom left;
    transform: scaleX(1);
  }

  a.active {
    border-bottom: 2px solid ${props => props.theme.colors.white};
  }
  ${above.md`
    font-size: 0.9em;
 `}
  
`
const NavWrapper = styled(Flex)`
  margin: 0;
  padding: 0;
  ${props =>
    props.type === 'main' &&
    css`
      display: block;
      flex-direction: column;
      width: 100%;
      padding: 0 0.75em;
      ${above.md`
      display: flex;
      flex-direction: row;
      width: auto;  
    `}
  `}
`

const Nav = props => (
  <NavWrapper as="ul" {...props}>
    <NavItem>
      <Link to="/" activeClassName="active">
        Home
      </Link>
    </NavItem>
    <NavItem>
      <Link to="/about" activeClassName="active">
        About
      </Link>
    </NavItem>
    <NavItem>
      <Link to="/services" activeClassName="active">
        Services
      </Link>
    </NavItem>
    <NavItem css={`margin: 0 2em 0 0`}>
      <Link to="/faq" activeClassName="active">
        FAQs
      </Link>
    </NavItem>
    <ContactLink />
  </NavWrapper>
)

export default Nav
