// Responsive type resources

/*
Type Scale: https://type-scale.com/

Modular Scale: https://www.modularscale.com/?1,1140,12&em&1.3

Blog Post: https://www.codementor.io/ricardozea/100-responsive-typography-system-using-a-modular-scale-s5rhft58g
*/

import { createGlobalStyle } from 'styled-components'

import { fonts, colors, fontSizes, lineHeights, above, below } from '../theme'

const Global = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  :focus {
    outline: thin dotted;
  }

  body{
    font-family: ${fonts.sans};
    line-height: ${lineHeights.normal};
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  img, table, iframe, video {
    max-width: 100%;
  }

  main{
    overflow: hidden;
  }

  a{
    color: ${props => props.theme.colors.primary[500]};
  }

  h1 {
    font-size: ${fontSizes[7]}px;
    line-height: ${lineHeights.tight};
    ${above.sm`
      font-size: ${fontSizes[8]}px;
    `};
    ${above.md`
      font-size: ${fontSizes[9]}px;
    `};
  }

  h2 {
    color: ${props => props.theme.colors.primary[500]};
    font-size: ${fontSizes[2]}px;
    line-height: ${lineHeights.tight};
    ${above.sm`
      font-size: ${fontSizes[3]}px;
    `};
  }

  h3 {
    font-size: ${fontSizes[4]}px;
    line-height: ${lineHeights.tight};
    ${above.sm`
      font-size: ${fontSizes[5]}px;
    `};
    ${above.md`
      font-size: ${fontSizes[6]}px;
    `};
  }

  h4 {
    font-size: ${fontSizes[2]}px;
    line-height: ${lineHeights.tight}
    ${above.sm`
      font-size: ${fontSizes[3]}px;
    `};
  }

  h5 {
    font-size: ${fontSizes[1]}px;
    line-height: ${lineHeights.tight}
    ${above.sm`
      font-size: ${fontSizes[2]}px;
    `};
  }

  h6 {
    font-size: ${fontSizes[1]}px;
    line-height: ${lineHeights.tight}
    ${above.sm`
      font-size: ${fontSizes[2]}px;
    `};
  }

  p, li {
    font-size: ${fontSizes[2]}px;
    line-height: ${lineHeights.normal};
  }

  .text-lg {
    font-size: ${fontSizes[4]}px;
    line-height: ${lineHeights.normal};
    ${above.lg`
      font-size: ${fontSizes[5]}px;
    `};
  }

  .text-sm {
    font-size: ${fontSizes[3]}px;
    line-height: ${lineHeights.normal};
    ${above.lg`
      font-size: ${fontSizes[4]}px;
    `};
  }

  h1, h2 {
    margin: 0 0 calc(14px + 1vw) 0;
  }
  h3, h4, h5, h6, p {
    margin: 0 0 calc(12px + 0.2vw) 0;
    font-weight: normal;
  }
  p:last-child {
    margin-bottom: 0;
  }

  
`
export default Global
