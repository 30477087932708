import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Headroom from 'react-headroom'
import { Box, Flex } from 'rebass'
import { above, below } from '../theme'

import Nav from '../elements/Nav'
import Logo from '../elements/Logo'
import Hamburger from '../elements/Hamburger'

const HeaderWrapper = styled(Box)`
  display: block;
  position: absolute;
  width: 100%;
`

const HeadroomWrapper = styled(Headroom)`
  .headroom--pinned {
    background-color: ${props => props.theme.colors.primary[500]};
    box-shadow: ${props => props.theme.shadows.md};
  }
`

const NavBar = styled(Flex)`
  align-content: flex-start;
  background-color: ${props =>
    props.isNavOpen === true ? props.theme.colors.primary[500] : 'transparent'};
  box-shadow: ${props =>
    props.isNavOpen === true ? '0 4px 8px 0 rgba(0,0,0,0.12)' : 'none'};
  flex-wrap: wrap;
  height: ${props => (props.isNavOpen === true ? '20em' : '96px')};
  overflow: ${props => (props.isNavOpen === true ? 'visible' : 'hidden')};
  padding: 0.5em;
  position: relative;
  transition: height 0.15s ease-out;
  z-index: 50;
  ${above.md`
      background-color: transparent;
      height: 96px;
      flex-wrap: no-wrap;
  `}
  .logo {
    display: block;
    width: 240px;
    padding: 0.5em 0;
    .blue {
      fill: ${props => props.theme.colors.white};
    }
    .gray {
      fill: ${props => props.theme.colors.gray[300]};
    }
  }
`

const Header = ({ props, children }) => {
  const [isNavOpen, setNavOpen] = useState(false)

  return (
    <HeaderWrapper id="header" width={1}>
      <HeadroomWrapper>
        <NavBar
          alignItems="center"
          justifyContent="space-between"
          isNavOpen={isNavOpen}
        >
          <Box width={[1 / 2, 'auto']} pl={8}>
            <Link to="/" aria-label="Logo">
              <Logo />
            </Link>
          </Box>
          <Box
            width={[1 / 2, 'auto']}
            pr={4}
            css={`
              ${below.md`
                text-align: right;
                position: relative;
                z-index: 50`}
              ${above.md`
                display: none;
              `}
            `}
            onClick={() => setNavOpen(!isNavOpen)}
          >
            <Hamburger />
          </Box>
          <Box width={[1, 1, 'auto']}>
            <Nav type="main" isNavOpen={isNavOpen} />
          </Box>
        </NavBar>
      </HeadroomWrapper>
    </HeaderWrapper>
  )
}

export default Header
