import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Box, Flex } from 'rebass'
import styled from 'styled-components'

import Container from '../layout/Container'
import Section from '../layout/Section'

const CtaWrapper = styled(Section)`
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding-top: 0;

  h2 {
    color: ${props => props.theme.colors.white};
  }
`

function CallToAction(props) {
  const data = useStaticQuery(graphql`
    query GlobalsQuery {
      prismicGlobals {
        data {
          address {
            html
          }
          address_alt {
            html
          }
          email_address {
            text
          }
          phone_number {
            text
          }
          site_name {
            text
          }
          hero_text {
            text
          }
          global_hero_image {
            fluid(maxWidth: 1500) {
              ...GatsbyPrismicImageFluid_noBase64
            }
          }
        }
      }
    }
  `)
  const doc = data.prismicGlobals.data

  return (
    <CtaWrapper id="contact">
      <BackgroundImage Tag="div" fluid={doc.global_hero_image.fluid}>
        <Container pt={[17]}>
          <Flex flexWrap="wrap" mx={-4}>
            <Box width={[1, 1, 4 / 5, 3 / 4]} px={4}>
              <h2>Contact</h2>
              <h3>{doc.hero_text.text}</h3>
            </Box>
            <Box width={1} px={4}>
              <Flex flexWrap="wrap" mx={-4}>
                <Box width={[1, 1, 1 / 3]} px={4} mb={[8, 8, 0]}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: doc.address.html,
                    }}
                  />
                </Box>

                <Box width={[1, 1, 1 / 3]} px={4} mb={[8, 8, 0]}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: doc.address_alt.html,
                    }}
                  />
                </Box>

                <Box width={[1, 1, 1 / 3]} px={4} mb={[8, 8, 0]}>
                  <p>
                    Phone: {doc.phone_number.text}
                    <br />
                    {doc.email_address.text}
                  </p>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Container>
      </BackgroundImage>
    </CtaWrapper>
  )
}

export default CallToAction
