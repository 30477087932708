import React from 'react'

const Logo = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 592.7 162.3"
    className="logo"
  >
    <g className="gray">
      <path d="M454.2 4.1c113.3-8.1 110.3 26.8 71.3 54-68.8 48-225.2 90.8-303.4 88.9 0 0 159 4.8 309.9-80 88.1-53.2 2.7-77.4-77.8-62.9zM98.5 92.4h2v12h6.2v1.8h-8.2V92.4zM115.6 92.3h1.4l6.2 13.8h-2l-1.8-4.1h-5.9l-1.7 4.1h-2l5.8-13.8zm2.9 8l-2.2-5-2.1 5h4.3zM138.4 92.4h1.9v13.8h-1.7l-9.2-10.6v10.6h-1.9V92.4h1.6l9.3 10.7V92.4zM146 106.1V92.4h4.6c1.9 0 3.3.3 4.4.8 1.1.6 2 1.4 2.6 2.5.6 1.1 1 2.3 1 3.6 0 .9-.2 1.8-.5 2.6-.4.8-.9 1.6-1.5 2.2-.7.7-1.4 1.2-2.3 1.5-.5.2-1 .3-1.4.4s-1.3.1-2.5.1H146zm4.5-12H148v10.2h2.5c1 0 1.7-.1 2.3-.2.5-.1 1-.3 1.4-.5.4-.2.7-.5 1-.8 1-1 1.5-2.2 1.5-3.7s-.5-2.7-1.5-3.6c-.4-.3-.8-.6-1.3-.9-.5-.2-.9-.4-1.4-.4-.4-.1-1.1-.1-2-.1zM183.3 92.4h1.8v13.8h-2V95.4l-4.2 5.3h-.4l-4.3-5.3v10.7h-2V92.4h1.9l4.6 5.6 4.6-5.6zM195.3 92.3h1.4l6.2 13.8h-2L199 102h-5.9l-1.7 4.1h-2l5.9-13.8zm2.9 8l-2.2-5-2.1 5h4.3zM218.1 92.4h1.9v13.8h-1.7l-9.2-10.6v10.6h-1.9V92.4h1.6l9.3 10.7V92.4zM230.2 92.3h1.4l6.2 13.8h-2L234 102h-6l-1.7 4.1h-2l5.9-13.8zm2.9 8l-2.2-5-2.1 5h4.3zM248.7 99.3h4.6v5.8c-1.7.7-3.4 1.1-5 1.1-2.3 0-4.1-.7-5.5-2-1.4-1.3-2-3-2-4.9 0-2.1.7-3.8 2.1-5.1 1.4-1.4 3.2-2 5.3-2 .8 0 1.5.1 2.2.3s1.6.5 2.6.9v2c-1.6-1-3.3-1.4-4.9-1.4-1.5 0-2.8.5-3.8 1.5s-1.5 2.3-1.5 3.8c0 1.6.5 2.8 1.5 3.8s2.4 1.5 4 1.5c.8 0 1.7-.2 2.8-.5l.2-.1v-2.9h-2.6v-1.8zM259 92.4h7.8v1.8H261v4.2h5.6v1.8H261v4.3h6v1.8h-8V92.4zM282.8 92.4h1.8v13.8h-2V95.4l-4.2 5.3h-.4l-4.3-5.3v10.7h-2V92.4h1.9l4.6 5.6 4.6-5.6z" />
      <path d="M290.4 92.4h7.8v1.8h-5.8v4.2h5.6v1.8h-5.6v4.3h6v1.8h-8V92.4zM314.3 92.4h1.9v13.8h-1.7l-9.2-10.6v10.6h-1.9V92.4h1.6l9.3 10.7V92.4zM320.7 92.3h11.5v1.8h-4.8v12h-2v-12h-4.7v-1.8zM343.7 92.4h2v13.8h-2V92.4zM362.5 92.4h1.9v13.8h-1.7l-9.2-10.6v10.6h-1.9V92.4h1.6l9.3 10.7V92.4zM382 102.9v2.2c-1.4.8-3.1 1.2-5 1.2-1.5 0-2.8-.3-3.9-.9-1.1-.6-1.9-1.5-2.5-2.5-.6-1.1-.9-2.3-.9-3.5 0-2 .7-3.7 2.1-5.1 1.4-1.4 3.2-2 5.3-2 1.4 0 3 .4 4.8 1.1v2.1c-1.6-.9-3.2-1.4-4.7-1.4-1.6 0-2.9.5-3.9 1.5s-1.6 2.2-1.6 3.7.5 2.7 1.5 3.7 2.3 1.5 3.9 1.5c1.7 0 3.3-.6 4.9-1.6zM387.6 103.9c.3 0 .6.1.9.3.2.2.4.5.4.8 0 .3-.1.6-.3.8-.2.2-.5.3-.9.3-.3 0-.6-.1-.8-.3-.2-.2-.3-.5-.3-.8 0-.3.1-.6.3-.8.1-.2.4-.3.7-.3z" />
    </g>
    <g className="blue">
      <path d="M522.5 55.4c-12.1-3-26.8-5.7-44.2-7.8 16.3.4 33.1 2.3 47.9 5.3l-3.7 2.5zm23.2 7.4c44.9 18.5 27.5 44.5-5.6 57.3C428 163.3 288 166 205.2 148.5c0 0 146.9 39.9 334.4-16 78.4-26.9 59.7-57.2 10.7-73.3l-4.6 3.6zM54.5 63.6c2.5 2.1 4.1 5.3 4.1 8.8C58.6 78.8 53.4 84 47 84H0V43h47c6.4.1 11.6 5.3 11.6 11.7 0 3.5-1.6 6.7-4.1 8.9zm-1.4-8.9c0-3.3-2.8-6.1-6.1-6.1H5.5v12.2H47c3.3 0 6.1-2.7 6.1-6.1zm0 17.7c0-3.4-2.8-6.1-6.1-6.1H5.5v12.2H47c3.3 0 6.1-2.7 6.1-6.1zM123.1 54.7v4.5c0 6.1-4.7 11.1-10.7 11.6l9 13.3h-6.7l-9-13.2H70V84h-5.6V43h47c6.4.1 11.7 5.3 11.7 11.7zm-5.5 0c0-3.3-2.8-6.1-6.1-6.1H70v16.6h41.4c3.4 0 6.1-2.8 6.1-6.1v-4.4zM128.9 43h5.6v41h-5.6V43zM198.9 43v5.6h-47c-3.3 0-6 2.8-6 6.1v17.7c0 3.3 2.7 6.1 6 6.1h35.4c3.3 0 6-2.8 6-6.1v-6.1h-26.5v-5.6h32v11.7c0 6.4-5.2 11.6-11.5 11.6h-35.4c-6.4 0-11.5-5.2-11.5-11.6V54.7c0-6.4 5.2-11.6 11.5-11.7h47zM257.8 43h5.5v41h-5.5V66.4h-47.6V84h-5.5V43h5.5v17.7h47.6V43zM327.7 43v5.6h-26.5V84h-5.5V48.6h-26.6V43h58.6zM392.1 54.7v17.7c0 6.4-5.2 11.6-11.6 11.6h-35.4c-6.4 0-11.6-5.2-11.6-11.6V54.7c0-6.4 5.2-11.6 11.6-11.7h35.4c6.5.1 11.6 5.3 11.6 11.7zm-5.5 0c-.1-3.3-2.8-6.1-6.1-6.1h-35.4c-3.3 0-6 2.8-6.1 6.1v17.7c.1 3.3 2.8 6.1 6.1 6.1h35.4c3.3 0 6-2.8 6.1-6.1V54.7zM451.1 43h5.5v35c0 3.3-2.8 6-6 6-1.4 0-2.8-.4-3.7-1.2l-42.5-34.1c-.1-.1-.1-.1-.2-.1-.3 0-.5.2-.5.5v35H398v-35c0-3.3 2.8-6 6.1-6 1.3 0 2.7.5 3.7 1.3l42.5 34.1c.1.1.2.1.4.1s.5-.2.5-.5V43z" />
    </g>
  </svg>
)

export default Logo
