import React from 'react'
import { Link as LinkTo, animateScroll as scroll } from 'react-scroll'
import styled from 'styled-components'

import { above } from '../theme'

const ContactLinkWrapper = styled(LinkTo)`
  background-color: transparent;
  border: 2px solid ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.radii.full};
  color: ${props => props.theme.colors.white};
  font-size: 0.9em;
  padding: 4px 14px;
  position: relative;
  cursor: pointer;
  top: 1em;
  &:hover {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.black};
  }
  ${above.md`
    position: initial;
 `}
`

function ContactLink(props) {
  return (
    <ContactLinkWrapper
      to="contact"
      spy
      smooth
      offset={-70}
      duration={500}
    >
      Contact
    </ContactLinkWrapper>
  )
}

export default ContactLink
