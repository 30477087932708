import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Box, Flex } from 'rebass'
import config from '../../../config/website'

import Container from './Container'
import Logo from '../elements/Logo'
import LinkExt from '../elements/LinkExt'

const FooterWrap = styled(Box)`
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.gray[200]};
  a {
    color: ${props => props.theme.colors.gray[200]};
  }
  .logo {
    width: 160px;
    opacity: 0.5;
    fill: ${props => props.theme.colors.gray[100]};
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  ul li {
    padding: 0.25em 0;
    font-size: 14px;
  }
  ul li a {
    text-decoration: none;
  }
`
const FooterCredits = styled(Box)`
  border-top: 1px solid ${props => props.theme.colors.gray[800]};
  text-align: center;
  font-size: 14px;
`

const Footer = () => {
  const d = new Date()
  const n = d.getFullYear()

  return (
    <FooterWrap>
      <Container>
        <Flex flexWrap="wrap" py={12} mx={-6}>
          <Box width={[1, 1, 1 / 4, 1 / 4]} px={6}>
            <Logo />
          </Box>
          <Box width={[1, 1, 1 / 4]} px={6}>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/faq">FAQs</Link>
              </li>
            </ul>
          </Box>
          <Box width={[1, 1, 1 / 4]} px={6}>
            <ul>
              <li>
                <Link to="/services#renewables">Renewables</Link>
              </li>
              <li>
                <Link to="/services#right-of-way">Right of Way</Link>
              </li>
              <li>
                <Link to="/services#land-administrations">
                  Land Administrations
                </Link>
              </li>
              <li>
                <Link to="/services#project-management">Project Management</Link>
              </li>
            </ul>
          </Box>
          <Box width={[1, 1, 1 / 4]} px={6}>
            <ul>
              <li>
                <Link to="/services#title-research">Title Research</Link>
              </li>
              <li>
                <Link to="/services#oil-and-gas-land-acquisition">
                  Oil & Gas Land Acquisition
                </Link>
              </li>
              <li>
                <Link to="/services#reverse-genealogy">Reverse Genealogy</Link>
              </li>
            </ul>
          </Box>
        </Flex>
        <FooterCredits width={1} py={4}>
          &copy; {n} Copyright - {config.siteTitle}, Website Design &
          Development:{' '}
          <LinkExt to="https://www.mindmergedesign.com">
            Mind Merge Design
          </LinkExt>
        </FooterCredits>
      </Container>
    </FooterWrap>
  )
}

export default Footer
